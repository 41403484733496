import  React from "react"
import Layout from "../../components/layout"
import { Link } from 'gatsby'
import Head from '../../components/head'
import LatQuotesIntro from './LatQuotesIntro'

// markup
const LatQuotes_o = () => {
  return (
   <Layout >
      <Head title='Latin Quotes and Expressions - O'/>
     <h1>LATIN QUOTES AND EXPRESSIONS</h1>
     <h2>O</h2>
     <LatQuotesIntro />
    
     <p><b>O curas hominum! O quantum est in rebus inane!</b> - Ah, human cares! Ah, how much futility in the world! (Lucilius)</p>
<p><b>O di immortales!</b> - Immortal gods! Good heavens!</p>
<p><b>O diem praeclarum!</b> - Oh, what a beautiful day!</p>
<p><b>O praeclarum custodem ovium lupum!</b> - An excellent protector of sheep, the wolf! (Cicero)</p>
<p><b>O quam cito transit gloria mundi!</b> - O how quickly passes the glory of the world!</p>
<p><b>O sancta simplicitas!</b> - Oh, holy simplicity! (Jan Hus)</p>
<p><b>O tempora, O mores!</b> - Oh, the times! Oh, the morals! (Cicero)</p>
<p><b>Obiit (ob.)</b> - He/she died</p>
<p><b>Obiter (ob.)</b> - In passing</p>
<p><b>Obiter dictum</b> - Something said in passing</p>
<p><b>Obscurum per obscurius</b> - The obscure by means of the more obscure</p>
<p><b>Obsta principiis</b> - Resist the beginnings</p>
<p><b>Occasio aegre offertur, facile amittitur</b> - Opportunity is offered with difficulty, lost with ease. (Publius Syrus)</p>
<p><b>Occasio facit furem</b> - Opportunity makes a thief</p>
<p><b>Oderint dum metuant</b> - Let them hate provided that they fear. (Seneca)</p>
<p><b>Odi et amo</b> - I hate (her), and I love (her) (Catullus)</p>
<p><b>Odium theologicum</b> - Theological hatred. (a special name for the hatred generated in theological disputes)</p>
<p><b>Oleum addere camino</b> - To pour fuel on the stove adding gasoline to a fire</p>
<p><b>Oleum perdisti</b> - You have lost oil you've wasted your time on this criticism for a misallocation of resources</p>
<p><b>Olim</b> - Formerly</p>
<p><b>Omne ignotum pro magnifico est</b> - We have great notions of everything unknown. (Tacitus)</p>
<p><b>Omne initium est difficile</b> - Every beginning is difficult</p>
<p><b>Omne trium perfectum</b> - Everything that comes in threes is perfect</p>
<p><b>Omne tulit punctum qui miscuit utile dulci</b> - [he] has gained every point who has combined [the] useful [with the] agreeable</p>
<p><b>Omnes aequo animo parent ubi digni imperant</b> - All men cheerfully obey where worthy men rule. (Syrus)</p>
<p><b>Omnes deteriores sumus licentia</b> - Too much freedom debases us</p>
<p><b>Omnes una manet nox</b> - The same night awaits us all. (Horace)</p>
<p><b>Omnes vulnerant, ultima necat</b> - All (hours) wound, the last kills. (inscription on solar clocks)</p>
<p><b>Omnia iam fient quae posse negabam</b> - Everything which I used to say could not happen will happen now. (Ovid)</p>
<p><b>Omnia mea mecum porto</b> - All that is mine, I carry with me. (My wisdom is my greatest wealth) (Cicero)</p>
<p><b>Omnia mors aequat</b> - Death makes all things equal</p>
<p><b>Omnia munda mundis</b> - Everything is pure to pure ones</p>
<p><b>Omnia mutantur nos et mutamur in illis</b> - All things change, and we change with them</p>
<p><b>Omnia mutantur, nihil interit</b> - Everything changes, nothing perishes. (Ovid)</p>
<p><b>Omnia mutantur, nos et mutamur in illis</b> - All things are changing, and we are changing with them</p>
<p><b>Omnia vincit amor; et nos cedamus amori</b> - Love conquers all things; let us too surrender to love. (Vergil)</p>
<p><b>Omnium rerum principia parva sunt</b> - Everything has a small beginning. (Cicero)</p>
<p><b>Onus probandi</b> - The burden of proof</p>
<p><b>Opere citato (op. cit.)</b> - In the work just quoted</p>
<p><b>Optimis parentibus</b> - To my excellent parents. A common dedication in a book</p>
<p><b>Optimus magister, bonus liber</b> - The best teacher is a good book</p>
<p><b>Opus Dei</b> - The work of God</p>
<p><b>Ora et labora</b> - Pray and work. (St. Benedict)</p>
<p><b>Ora pro nobis</b> - Pray for us</p>
<p><b>Orator fit, poeta nascitur</b> - An orator is made [but] a poet is born</p>
<p><b>Orbiter dictum/dicta</b> - Said by the way (miscellaneous remarks)</p>

   </Layout>
  )
}

export default LatQuotes_o
